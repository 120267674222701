import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/button";
import { PasswordInput } from "@/components/inputs/password-input";
import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";
import { customErrorMap } from "@/utils/custom-error-map";

const formSchema = z.object({
  email: z.string().email().nonempty(),
});

const ResetPasswordPage = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: { email: "" },
    resolver: zodResolver(formSchema, { errorMap: customErrorMap }),
  });

  const onSubmit = (data: FieldValues) => {
    console.log(data);
  };

  return (
    <UnauthenticatedPageLayout
      title="Reset your password"
      className="max-w-screen-sm w-full mx-auto border rounded-lg bg-gray-100 p-4 flex flex-col gap-4 items-center"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 items-center w-full max-w-md"
      >
        <PasswordInput
          name="password"
          label="New Password"
          control={control}
          placeholder="Create your password"
          hint="Must be at least 8 characters long."
        />

        <Button type="submit" className="btn-primary" loading={isSubmitting}>
          Update Password
        </Button>
      </form>
    </UnauthenticatedPageLayout>
  );
};

export default ResetPasswordPage;
